.main {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: white;
    margin-top: 3%;
}

.day-picker {
    text-align: center;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.calendar-contents {
    margin-top: 30px;
    font-size: large;

}

#d-day {
    font-size: 100px;
    color: #ffaa00;
}

.checkbox-container {
    border: 1px white solid;
    width: 70%;
    max-width: 330px;
    margin: auto;
    margin-top: 20px;
    padding: 5%;
}

.rdp {
    --rdp-cell-size: 43px;
    --rdp-caption-font-size: 20px;
    --rdp-accent-color: #ffaa00;
    --rdp-background-color: #ffaa00;
    --rdp-accent-color-dark: #ffaa00;
    --rdp-background-color-dark: #ffaa00;
    --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
    --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
  
    margin: 1em;
  }

.rdp-nav_button {
    --rdp-background-color: none;
}

.rdp-day {
    font-size: 19px
}

.rdp-day_today:not(.rdp-day_outside) {
    font-weight: bold;
    text-underline-offset: 5px;
    text-decoration: underline;
}

#save-btn {
    background-color: #ffaa00;
    color: black;
    margin-top: 20px;
}

.memo-div {
    margin-top: 25px;
    margin-bottom: 25px;
}

footer {
    margin-top: 70px;
    margin-bottom: 100px;
  }